<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "map-view",
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "39341f6239c33487c6b19b29169c4a70", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale",
          "AMap.ToolBar",
          "AMap.Geolocation",
          "AMap.Driving",
          "AMap.MoveAnimation",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Geocoder",
          "AMap.CitySearch",
          "AMap.DistrictSearch",
          "AMap.LngLat"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              zoom: 13,
              center: [120.12, 30.27],
              resizeEnable: true,
              dragEnable: false
            });
            let driving = new AMap.Driving({
              map: this.map
            });
            this.$emit("initMap", driving);
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
};
</script>
<style scoped>
#container {
  width: 100%;
  height: 100%;
}
</style>
