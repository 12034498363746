<template>
  <div class="container">
    <div style="height: 300px">
      <gd_map @initMap="initMap"/>
    </div>
    <div class="header">
      <van-cell-group>
        <van-cell title="订单号">{{ order.orderNo }}</van-cell>
        <van-cell title="出发地">{{ order.startAddress }}</van-cell>
        <van-cell title="目的地">{{ order.endAddress }}</van-cell>
        <van-cell title="乘车人姓名">{{ order.passengerName }}</van-cell>
        <van-cell title="乘车人手机号">{{ order.passengerPhone }}</van-cell>
        <van-cell title="乘车人身份证号">{{ order.passengerCardNo }}</van-cell>
        <van-cell title="出发时间">{{ order.startTime }}</van-cell>
        <van-cell title="订单状态">{{ order.statusName }}</van-cell>
      </van-cell-group>
      <van-submit-bar v-if="order.orderStatus === 3" label="总金额 :" tip="仅支持支付宝支付,请确定手机上已安装支付宝!"
                      :price="order.payPrice * 100"
                      :loading="loading"
                      button-text="去支付"
                      @submit="onPay"/>
    </div>


  </div>
</template>

<script>
import * as orderApi from "@/api/order";
import gd_map from "@/components/map/index";
import {Toast} from "vant";

export default {
  name: 'Home',
  components: {gd_map},
  data() {
    return {
      order: {
        id: 1,
        orderNo: '',
        startAddress: '',
        endAddress: '',
        passengerName: '',
        passengerPhone: '',
        passengerCardNo: '',
        startTime: '',
        statusName: '',
        orderStatus: 0
      },
      driving: null,
      loading: true
    }
  },
  created() {
    this.getOrderInfo();
  },
  methods: {
    initMap(driving) {
      this.driving = driving;
    },
    getOrderInfo() {
      let shortNo = this.$route.params.shortNo;
      console.log('参数信息:', shortNo);
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'circular',
        duration: 0
      });
      if (shortNo) {
        let that = this;
        orderApi.orderInfo(shortNo).then(res => {
          that.order = res.data;
          toast.clear();
          that.loading = false;
          //为防止地图可能未加载好的情况,地图延迟1秒规划路线
          setTimeout(() => {
            // 根据起终点经纬度规划驾车导航路线
            that.driving.search([{keyword: res.data.startAddress, city: '杭州'}, {
              keyword: res.data.endAddress,
              city: '杭州'
            }], function (status) {
              if (status === 'complete') {
                console.log('绘制驾车路线完成')
              } else {
                console.log('获取驾车数据失败：' + status)
              }
            });
          }, 1000);
        })
      } else {
        toast.clear();
        that.loading = false;
        this.close("订单信息错误");
      }
    },
    close(message) {
      Toast.fail({
        message: message,
        duration: 3000,
        onClose: () => {
          window.close();
        }
      });
    },
    onPay() {
      let data = {
        clientType: 0,
        orderId: this.order.id,
        returnUrl: window.location.origin + "/payResult",
      };
      orderApi.orderPay(data).then(res => {
        console.log("h5支付");
        let divForm = document.getElementsByTagName('divform');
        if (divForm.length) {
          document.body.removeChild(divForm[0]);
        }
        const div = document.createElement('divform');
        div.innerHTML = res.data; // res.data就是支付宝返回给你的form
        document.body.appendChild(div);
        document.forms[0].submit();
        div.remove();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  background-color: #F7F7F7;

  .header {
    // padding: 0 25px;
    background-color: #fff;
  }

  .content {
    margin-top: 20px;
    padding: 0 50px;
    background-color: #fff;
  }
}
</style>
